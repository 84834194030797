<template>
  <form-post
    ref="formPost"
    :params="params"
    route="/admin/site-admin-session"
  ></form-post>
</template>

<script>
export default {
  data: () => ({
    csrfToken: null,
  }),
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$nextTick()
      this.$store.dispatch('loading/active')
      this.$store.dispatch('loading/progress')
      await this.$helper.delay(1)
      this.$refs.formPost.submit()
    },
  },
  computed: {
    path() {
      return this.$route.query.path
    },
    tokenStore() {
      return this.$store.getters['token/tokenStore']
    },
    params() {
      return {
        token: this.tokenStore.get(),
        fingerprint: this.$fingerprint.get(),
        redirect: this.path,
      }
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>
